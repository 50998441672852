.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-color);
}

.loaderBox {
    display: flex;
    align-items: center;
    gap: 6px;
}

.loader {
    width: 45px;
    height: 45px;
    color: var(--white-color);

}
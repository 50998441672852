.navbar {
  background-color: transparent;
  position: absolute;
  top: 0;

  transform: translate(-50%, -50%);
  left: 50%;
  top: 7%;
  z-index: 9 !important;
}
a {
  color: #fff !important;

  text-decoration: unset !important;
}
.brandContainer {
  width: 130px;
  height: 130px;

  margin: 0 40px;
}

.brandContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 350px) {
  .brandContainer {
    width: 80px;
    height: 80px;
  }
}

/* Rubik Regular=400 Medium=500 Bold=700  */
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");

:root {
  --primary-color: #252525;
  --secondary-color: #333333;
  --light-gray-color: #707070;
  --input-color: #444444;
  --white-color: #ffffff;
  --text-color-black: #000000;
  --placeholder-color: #828282;
  --light-text: #a2a2a2;
}
img {
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;
}

.img_loader_main {
  position: relative;
  height: 100%;
}
.img_loader {
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 233.8px;
}
@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}

* {
  font-family: "Rubik", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--white-color);
}

body {
  background-color: var(--primary-color) !important;
  overflow-x: hidden;
}

p {
  letter-spacing: 0px;
  color: var(--white-color);
  font-family: "Rubik", sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 34px !important;
}

h1 {
  letter-spacing: -1.65px;
  color: #ffffff;
  font-size: 55px !important;
  line-height: 65px !important;
  font-family: "Rubik", sans-serif !important;
  font-weight: 500 !important;
}
h2 {
  letter-spacing: -1.65px;
  color: #ffffff;
  white-space: pre-line;
  font-size: 45px !important;
  line-height: 55px !important;
  font-family: "Rubik", sans-serif !important;
  font-weight: 500 !important;
}
.porfolio_dots {
  padding-bottom: 30px !important;
}
.maxLine1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.maxLine4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.maxLine5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.c-p {
  cursor: pointer;
}


/* 
.scroll-container,
.scroll-area {
  height: 600px;
  font-size: 60px;
}

.scroll-container {
  overflow: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

}

.scroll-area {
  scroll-snap-align: start;
}

.scroll-container,
.scroll-area {
  margin: 0 auto;
}

.scroll-area {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
} */








#refernce_b {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page {
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
height: 100vh;
  color: #000;
}





















.t-t-c {
  text-transform: capitalize;
}

.carousel-item-padding-40-px {
  padding: 10px;
}

.nav-link {
  color: var(--white-color) !important;
  font-family: "Rubik", sans-serif !important;
  font-size: 18px;
  line-height: 34px;
}
@media (max-width: 1600px) {
  h1 {
    font-size: 35px !important;
    line-height: 1 !important;
  }
  p {
    font-size: 14px !important;
  }
}
@media (max-width: 992px) {
  h2 {
    font-size: 35px !important;
    line-height: 1 !important;
  }
}
@media (max-width: 500px) {
  h2 {
    font-size: 25px !important;
    line-height: 1 !important;
  }
}

/* Loader */
.simple-loader {
  border: 5px solid #fff;
  border-radius: 50%;
  border-top: 5px solid var(--input-color);
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  /* background-image: url("../../assets/images/hero-section-bg-with-overlay.png"); */
  min-height: 100vh;
  min-width: 100%;
  display: grid;
  place-items: center;
}

.main {
  background-color: #fff;
  padding: 50px;
  border: 1px solid #707070;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 50%;
}

.main h1 {
  letter-spacing: 4px;
  color: #fa9a94;
  text-transform: capitalize;
  font-style: italic;
  font-family: SF-Heavy-Italic;
  font-size: 90px !important;
}

.main h3 {
  letter-spacing: 1.6px;
  color: #4a4f74;
  text-transform: uppercase;
  font-style: italic;
  font-family: SF-Heavy-Italic;
  font-size: 28px !important;
  margin: 10px 0;
}

.main p {
  text-align: center;
  font: SF-Regular;
  letter-spacing: 0px;
  color: #c5c5c5;
  width: 40%;
  margin: 0px auto 10px auto;
}

.main button {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.imageContainer {
  width: 290px;
  height: 206px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  background: unset;
  
  object-fit: contain;

}

.customButton {
  background-color: #4a4f74 !important;
  width: 200px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
}

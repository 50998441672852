.portfolio_container {
  max-width: 85%;
}
/* .mar_top_title{
    margin-top: 70px !important;
} */

.portfolio_container {
  margin-top: 8%;
}
.portfolio_container h1 {
  text-align: center;
  margin-top: 50px;
}

.cusRow {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal cusColumns that sits next to each other */
.cusColumn {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.cusColumn img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}


.dailyWorkImagesConti{
  width: 375px;
  height: 265px;
  margin-bottom: 20px;
}
.dailyWorkImagesConti img{
  width: 100%;
  height: 100%;
}


.loadMorecontainer {
  display: grid;
  place-items: center;
  margin-top: 20px;
}

/* Responsive layout - makes a two cusColumn-layout instead of four cusColumns */
@media screen and (max-width: 450px) {
  
  .dailyWorkImagesConti img{
    object-fit: cover;
    object-position: center;
  }

  
}
@media screen and (max-width: 1050px) {
  .mar_top_title {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 800px) {
  .cusColumn {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two cusColumns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .cusColumn {
    flex: 100%;
    max-width: 100%;
  }
}

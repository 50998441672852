.footer {
  margin-right: 40px;
  background-image: url(../../assets/images/footerImg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 60px 0;
  height: 100vh;
  display: flex;
  align-items: center;
}

.container {
  background-color: var(--secondary-color);
  padding: 40px;
}

.logoContainer {
  width: 166px;
  height: 121px;
  margin-bottom: 60px;
}

.logoContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.info p a {
  color: inherit;
  text-decoration: none;
}

.info p {
  color: var(--light-text);
  letter-spacing: 0px;
  margin-bottom: 0px !important;
}

.socialContainer {
  margin-top: 20px;
}

.socialContainer a {
  width: 42px !important;
  height: 42px !important;
  margin-right: 10px;
}

.lighterText {
    color: var(--light-text);
    line-height: 65px;
}

.form h1{
    white-space: pre-line;
    font-size: 45px !important;
    line-height: 55px !important;

}
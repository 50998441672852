.textAreaBox label {
  margin-bottom: 10px;
}

.textAreaBox textarea {
  border: 1px solid var(--light-gray-color);
  padding: 10px 20px;
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  outline: none;

  letter-spacing: 1.4px;
  background-color: var(--input-color);
  font-size: 18px;
  color: var(--white-color);
}

.textAreaBox textarea::placeholder {
  color: var(--placeholder-color);
}

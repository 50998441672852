.main {
  /* margin: 40px 0; */
  background-image: url(/src/assets/images/sec2Img.jpg);
  height: 711px;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  background-position: 0;
}
.custom_div {
  /* background-color: red; */
  height: 68%;
  width: 60%;
  position: absolute;
  top: 16%;
}
/* right */
.info {
  background-color: var(--secondary-color);
  padding: 40px;
  width: fit-content;
  /* max-width: 719px; */
  min-width: 598px;
  margin-top: 79px;
  transform: translate(-100px, 1px);
}

.lighterTxt {
  color: var(--light-text) !important;
  letter-spacing: 0 !important;
  position: relative;

  transform: translate(100%, 10%);
  width: fit-content;
}

.lighterTxt::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 40px;
  background-color: var(--input-color);
  top: 16px;
  left: -48px;
}

.para {
  color: var(--light-text) !important;
  font-size: 16px !important;
  white-space: pre-line;
  letter-spacing: 0 !important;
  line-height: 30px !important;
}
.img_div {
  height: 400px;
}


.mainRight {
  background-position: right;
  margin-right: 8%;
}

.mainRight .info {
  transform: translateX(8%);
}

.rightRow {
  flex-direction: row-reverse;
}

.rightRow .info {
  margin-left: auto;
}

.mainRow {
  align-items: center;
}
.containerStyles,
.mainRow,
.mtContainer {
  /* height: 100%; */
}

@media (max-width: 1199px) {
  .info {
    transform: unset !important;
  }
  .main {
    background-size: cover;
    background-position: center;
  }
  .mainRight {
    margin-right: 0%;
  }
  .mainRight .info {
    transform: unset;
  }
}

@media (max-width: 768px) {
  .main {
    align-items: flex-start;
  }
  .info {
    width: 100%;
    margin-top: 0px;
  }
}
@media (max-width: 610px) {
  .zero_col {
    /* padding: 0; */
  }
  .info {
    min-width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* width: 90%; */
    min-width: 80%;
  }
}

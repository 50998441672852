.btn {
  font-size: 16px;
  outline: none;
  border: none;
  padding: 10px 46px 10px 17px;
  border-radius: 50px;

  position: relative;
}

.btn::after {
  content: "→";
  position: absolute;
  right: 19px;
  transition: all 0.3s;
}
.btn:hover::after {
  transform: translateX(8px);
}

.lightOrange {
  background-color: transparent;
  color: var(--white-color);

  border: 1px solid #ffffff;
  border-radius: 5px;
}

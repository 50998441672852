.main {
  margin: 40px 0;
  height: 100vh;
  display: flex;
  align-items: center;
}

.container {
  max-width: 90%;
}

.info {
  margin-left: 200px;
}

.imageContainer {
  width: 49.0625vw;
  height: 50.89877010406812vh;
  margin-top: -92px;
  background-color: #eee;
  /* transition: all 10s; */
}

.imageContainer img {
  width: 100%;
  height: 100%;
}

/* .imageContainer:hover {
  transform: scale(0.8) translateY(60px);
} */

.lighterTxt {
  color: var(--light-text) !important;
  letter-spacing: 0 !important;
  position: relative;

  /* transform: translate(10%, 10%); */
  margin-left: 60px;
}

.lighterTxt::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 40px;
  background-color: var(--input-color);
  top: 16px;
  left: -57px;
}

.para {
  color: var(--light-text) !important;
  font-size: 16px !important;
  white-space: pre-line;
  letter-spacing: 0 !important;
  line-height: 30px !important;
}

@media (max-width: 1600px) {
  .para {
    font-size: 14px !important;
  }
  .info {
    margin-left: 50px !important;
  }
}
@media (max-width: 1450px) {
  .info {
    margin-left: 50px !important;
  }
  
}
@media (max-width: 1300px) {
  .imageContainer {
    height: auto;
    width: auto;
    margin-top:unset;
  }
  .cus_row_intro{
    align-items: center;
  }

}
@media (max-width:1000px) {
  .info {
    margin-left: 0px !important;
  }
}
@media (max-width:768px) {
  .main{
    align-items: flex-start;
  }
  .imageContainer {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    /* background-color: aqua; */
    /* transition: all 10s; */
  }
}

.mainSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url("../../assets/images/heroImg.png");

  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
}
.hero_cus_b {
  width: 65%;
}
.hero_cus_b h1 {
  font-size: 25px !important;
}

@media (max-width: 1550px) {
  .hero_cus_b {
    margin-top: 120px;
  }
}
@media (max-width: 850px) {
  .hero_cus_b {
    width: 100%;
  }
}

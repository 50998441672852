.section___container {
    margin-top: 100px;
    display: block;
    width: 500px;
    height: 400px;
    background: #fbfbfb;
    overflow-x: hidden;
  }
  .section___container .section___scroll {
    display: block;
    height: 100%;
  }

